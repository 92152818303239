import BaseService from './BaseService';
import { AxiosRequestConfig } from 'axios';

class RestService extends BaseService {
    getId(entity: any, id?: any) {
        if (id) return id;
        if (typeof entity === 'string') return entity;
        return entity && (entity.id || entity._id);
    }

    create(entity: any, options?: AxiosRequestConfig) {
        return this.post(this.baseUrl, entity, options);
    }

    fetch(entityId: any) {
        return this.get(`${this.baseUrl}/${entityId}`);
    }

    list(params?: any) {
        return this.get(`${this.baseUrl}`, params);
    }

    update(entity: any, id: any) {
        return this.put(`${this.baseUrl}/${this.getId(entity, id)}`, entity);
    }

    delete(entityId: any, params: any) {
        return this.del(`${this.baseUrl}/${this.getId(entityId)}`, params);
    }
}

export default RestService;
