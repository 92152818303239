import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { Box, Button, Container, Grid, ImageList, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Merchant from '../../../../stores/models/Merchant';
import { useStores } from '../../../../hooks/use-stores';
import StarIcon from '@mui/icons-material/Star';
import Search from '../../../../components/Search';

const Wrapper = styled(Container)(({ theme }) => ({
    padding: '0px !important'
}));

const MerchantImage = styled('img')(({ theme }) => ({
    backgroundSize: 'contain',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '400px',
    borderRadius: '10px',
    [theme.breakpoints.up('sm')]: {
        fontSize: 18,
    },
}));

const SeeAllButton = styled(Button)(({ theme }) => ({
    textDecoration: 'underline',
    textTransform: 'none',
    color: 'white'
}));

const SearchWrapper = styled(Box)(({ theme }) => ({
    display: 'none',
    width: '100%',
    maxWidth: '400px',
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        transform: 'scale(1) translate(20%, 50%)',
        backgroundColor: 'rgb(246, 201, 69)',
        color: 'black',
        fontSize: '14px',
        borderRadius: '10px',
        fontWeight: 'bold'
    },
}));

interface Props {
    title?: string;
    subtitle?: string;
    merchants: Merchant[];
    // we need to be able to filter by merchants; include the category so we can filter; can also be 'featured'
    category: string;
    allowSeeAll?: boolean
    allowSearch?: boolean;
    largeDesktopImages?: boolean; // if true, the merchants images will be large on non-mobile screens
}

const FeaturedMerchant = (props: Props) => {
    const { giftcardStore } = useStores();
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState('');
    if (!props.category || props.merchants?.length == 0) {
        return null;
    }

    // The category page displays the same for 
    const navigateToCategory = () => {
        giftcardStore.category = props.category;
        navigate('/giftcards/category');
    }

    const navigateToCheckout = (merchant: Merchant) => {
        giftcardStore.selectedMerchant = merchant;
        navigate('/giftcards/review');
    }

    const getMerchants = () => {
        if (props.allowSearch) {
            return giftcardStore.filterMerchantsByName(searchQuery, props.merchants)
        }
        return props.merchants;
    }

    const onSearchQueryUpdated = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }

    return (
        <Wrapper>
            <Grid container>
                <Grid item container xs={8} md={6}>
                    {props.title && <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="h5">{props.title}</Typography>
                    </Grid>
                    }
                    <Grid item xs={12} justifyContent="space-between">
                        {props.subtitle && <Typography variant="body2" sx={{ color: 'gray' }}>{props.subtitle}</Typography>}
                    </Grid>
                </Grid>
                <Grid item container xs={4} md={6} justifyContent="flex-end" >
                    {props.allowSeeAll ? <SeeAllButton onClick={navigateToCategory}>See all &gt;</SeeAllButton>
                        :
                        props.allowSearch &&
                        <SearchWrapper>
                            <Search searchQuery={searchQuery} onSearchQueryChanged={onSearchQueryUpdated} isSmall />
                        </SearchWrapper>
                    }
                </Grid>
            </Grid>
            <ImageList
                sx={{
                    gridAutoFlow: "column",
                    gridTemplateColumns: {
                        xs: "repeat(auto-fill, minmax(160px,1fr)) !important",
                        md: `repeat(auto-fill, minmax(${props.largeDesktopImages ? '400px' : '160px'},1fr)) !important`,
                    },
                    gridAutoColumns: {
                        xs: "minmax(160px, 1fr)",
                        md: `minmax(${props.largeDesktopImages ? '400px' : '160px'}, 1fr)`
                    },
                    gap: '15px !important',
                    marginTop: '14px'
                }}

            >
                {getMerchants().map((merchant: Merchant) => (
                    <StyledBadge
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        badgeContent={<><StarIcon fontSize='small'
                        />
                            {`${merchant.cashbackPercentage}%`}</>} color="secondary">
                        <ImageListItem key={merchant.slug} onClick={() => navigateToCheckout(merchant)}>
                            <MerchantImage src={merchant.imageUrl} />
                        </ImageListItem>
                    </StyledBadge>
                ))}
            </ImageList>
        </Wrapper>
    );
}

export default FeaturedMerchant;