import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Box, Container, styled, Typography } from '@mui/material';
import Merchants from './Merchants';
import Checkout from './Checkout';
import Category from './Category';
import Complete from './Complete';
import Review from './Review';

interface BreadcrumbProps {
    current?: boolean;
}

const Breadcrumb = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "current"
})<BreadcrumbProps>(({ theme, current }) => ({
    color: current ? 'red': "white",
    padding: '0 4px'
}));

const Giftcards = () => {
    const location = useLocation();
    const onCheckout = location.pathname.includes('checkout');
    const onReview = location.pathname.includes('review');
    const onMerchant = !onCheckout && !onReview;

    return (
        <Container sx={{ padding: 0 }}>
            <Box display="flex" sx={{ padding: '12px 0' }} alignItems="center" justifyContent={'space-between'}>
                <img src={`${process.env.PUBLIC_URL}/img/sparkLogo.png`} height="30" /> <Typography fontWeight="bold" variant="body1">Shop With Spark</Typography>
                <Box display="flex">
                    <Breadcrumb current={onMerchant}>Select</Breadcrumb>
                    -
                    <Breadcrumb current={onCheckout}>Review</Breadcrumb>
                    -
                    <Breadcrumb current={onReview}>Checkout</Breadcrumb>
                </Box>
            </Box>
            <hr />
            <Container>
                <Routes>
                    <Route
                        path="/checkout"
                        element={<Checkout />}
                    />
                    <Route
                        path="/category"
                        element={<Category />}
                    />
                    <Route
                        path="/review"
                        element={<Review />}
                    />
                    <Route
                        path="/complete"
                        element={<Complete />}
                    />
                    <Route
                        path="*"
                        element={<Merchants />}
                    />
                </Routes>
            </Container>
        </Container>
    )
}

export default Giftcards;