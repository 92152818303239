import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import {
    DialogContent,
    Paper,
    Box,
    Typography,
    DialogActions,
    CircularProgress,
    Button,
    Grid,
    SelectChangeEvent,
    MenuItem,
    Dialog,
} from '@mui/material';
import { useStores } from '../hooks/use-stores';
import MaskedTextField from '../components/MaskedMaterialTextField';
import TextField from '../components/TextField';
import Select from '../components/Select';
import DialogTitle from '../components/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { formatDOB } from '../utils/helpers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface Props {
    vfp?: string | null;
    finalize?: boolean;
}
const { REACT_APP_MY_URL } = process.env;

export default observer(function IdentityVerification(props: Props) {
    const { authStore, commonStore } = useStores();
    const {
        identityError,
        identityCheckError,
        gettingVerifyStatus,
        eligibilityLoading,
        possessionChecked,
        eligibilityForPrefill,
        identityLoading,
        identityResult,
        finalizeResult,
        finalizing,
        verificationsAttempts,
    } = authStore;

    const { regionsByCountry } = commonStore;
    const USRegions = regionsByCountry['US'] || [];
    const [dob, setDOB] = useState<Date | null>(null);
    const [last4, setLast4] = useState<string>('');
    const [step, setStep] = useState<number>(1);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [verificationComplete, setVerificationComplete] = useState<boolean>(false);
    const [wrongDataConfirmed, setWrongDataConfirmed] = useState<boolean>(false);
    const [eligibilityError, setEligibilityError] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        authStore.getVerifyStatus(true);
        commonStore.loadRegionsByCountry('US');
    }, []);

    useEffect(() => {
        if (gettingVerifyStatus === false) {
            if (!possessionChecked) {
                navigate('/verify_phone');
            } else {
                authStore.eligibility();
            }
        }
    }, [gettingVerifyStatus]);

    const handleVerifyIdentity = () => {
        if (step === 1) {
            return authStore.identity(dob, last4);
        }
        if (step === 2) {
            return authStore.verifyIdentity(firstName, lastName, dob, last4, city, address, region, postalCode);
        }
    };

    const isFormValid = useMemo(() => {
        if (step === 1) return last4 && last4.length === 4 && dob != null;
        if (step === 2)
            return last4 && last4.length === 4 && dob != null && lastName && firstName && city && address && postalCode && region;
    }, [step, last4, dob, city, region, postalCode, address, firstName, lastName]);

    const actionInProgress = useMemo(() => {
        if (step === 1) return last4 && last4.length === 4 && dob != null;
    }, [step]);

    const handleChangeLast4 = (e: ChangeEvent<HTMLInputElement>) => {
        setLast4(e.target.value);
    };

    const handleChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    };

    const handleChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    };
    const handleChangeAddress = (e: ChangeEvent<HTMLInputElement>) => {
        setAddress(e.target.value);
    };
    const handleChangeCity = (e: ChangeEvent<HTMLInputElement>) => {
        setCity(e.target.value);
    };
    const handleChangePostalCode = (e: ChangeEvent<HTMLInputElement>) => {
        setPostalCode(e.target.value);
    };
    const handleChangeRegion = (e: SelectChangeEvent) => {
        setRegion(e.target.value);
    };

    useEffect(() => {
        if (eligibilityLoading === false) {
            if (eligibilityForPrefill === null) setEligibilityError(true);
            else if (eligibilityForPrefill) setStep(1);
            else setStep(2);
        }
    }, [eligibilityLoading, eligibilityForPrefill]);

    useEffect(() => {
        if (finalizing === false) {
            if (finalizeResult?.verified) {
                debugger;
                //show  complete screen if redirection failed.
                setVerificationComplete(true);
                try {
                    if (window.parent)
                        window.parent.location.href = `${process.env.REACT_APP_MY_URL}/wallet/ewallet/add_more_money`;
                    else window.location.href = `${process.env.REACT_APP_MY_URL}/wallet/ewallet/add_more_money`;
                } catch (e) { }
            }
        }
    }, [finalizing, finalizeResult]);

    const handleOpenHelpPage = () => {
        if (window.parent) window.parent.location.href = `${process.env.REACT_APP_MY_URL}/starfish/profile/help`;
        else {
            window.location.href = `${process.env.REACT_APP_MY_URL}/starfish/profile/help`;
        }
    };

    const resetIdentityError = () => {
        authStore.resetIdentityError();
    };

    useEffect(() => {
        if (identityLoading === false) {
            if (identityResult && identityResult.verified) {
                setStep(2);
                setFirstName(identityResult.firstName || '');
                setLastName(identityResult.lastName || '');
                setDOB(identityResult.dob ? moment(moment.tz(identityResult.dob, 'UTC').startOf('day').format('LL')).startOf('day').toDate() : null);
                setLast4(identityResult.last4 || '');
                setCity(identityResult.city || '');
                setAddress(identityResult.address || '');
                setPostalCode(identityResult.postalCode || '');
                setRegion(identityResult.region || '');
            } else if (identityResult && identityResult?.verified === false) {
                //prefill failed, so user has to manually enter their information
                setStep(2);
                setFirstName('');
                setLastName('');
                setDOB(identityResult.dob ? moment(moment.tz(identityResult.dob, 'UTC').startOf('day').format('LL')).startOf('day').toDate() : null);
                setLast4(identityResult.last4 || '');
                setCity('');
                setAddress('');
                setPostalCode('');
                setRegion('');
            }
        }
    }, [identityLoading, identityResult]);

    return (
        <Box
            sx={{
                color: '#575962',
                height: '100%',
                width: '100%',
                outline: 0,
                display: 'flex',
                alignItems: 'flexStart',
                justifyContent: 'center',
                position: 'fixed',
            }}
        >
            {gettingVerifyStatus === null || gettingVerifyStatus || eligibilityLoading === null || eligibilityLoading ? (
                <CircularProgress />
            ) : (
                <Paper
                    sx={{
                        margin: 0,
                        width: 450,
                        maxWidth: '98%',
                        backgroundColor: 'black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <DialogTitle>Verification Needed</DialogTitle>
                    <DialogContent
                        sx={(theme) => ({
                            height: '100%',
                            padding: theme.spacing(4),
                            paddingTop: 0,
                            textAlign: 'center',
                        })}
                    >
                        {verificationComplete ? (
                            <>
                                <Typography variant={'subtitle1'}>Verification Complete!</Typography>
                            </>
                        ) : (
                            <>
                                {step === 1 && (
                                    <>
                                        <Typography>Please enter your date of birth and the last 4 digits of your Social Security Number.</Typography>
                                        <Typography>Ensure the information you submit is correct.</Typography>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                label="Date-of-Birth"
                                                value={dob}
                                                openTo={"year"}
                                                views={["year", "month", "day"]}
                                                onChange={(newValue) => {
                                                    // @ts-ignore
                                                    setDOB(
                                                        newValue && // @ts-ignore
                                                        moment.tz(newValue, 'UTC').endOf('day').toDate()
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        id="dob"
                                                        name={'dob'}
                                                        value={dob}
                                                        {...params}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        margin={'normal'}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        <MaskedTextField
                                            getInputRef={last4}
                                            format="####"
                                            fullWidth
                                            id="last4"
                                            name={'last4'}
                                            InputLabelProps={{ shrink: true }}
                                            label={'Last 4 of SSN'}
                                            margin={'normal'}
                                            value={last4}
                                            onChange={handleChangeLast4}
                                        />
                                        {/*{verificationError && (*/}
                                        {/*    <Alert severity="error">{verificationError}</Alert>*/}
                                        {/*)}*/}
                                    </>
                                )}
                                {step === 2 && (
                                    <>
                                        <Typography>
                                            Please fill out and verify the information below is correct and hit the Confirm button.
                                            Ensure the information you submit is correct.
                                        </Typography>
                                        <Typography>Ensure the information you submit is correct.</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    label={'Legal First Name'}
                                                    margin={'normal'}
                                                    value={firstName}
                                                    onChange={handleChangeFirstName}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    label={'Legal Last Name'}
                                                    margin={'normal'}
                                                    value={lastName}
                                                    onChange={handleChangeLastName}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        label="Date-of-Birth"
                                                        value={dob}
                                                        openTo={"year"}
                                                        views={["year", "month", "day"]}
                                                        onChange={(newValue) => {
                                                            // @ts-ignore
                                                            setDOB(
                                                                newValue &&
                                                                moment.tz(newValue, 'UTC').endOf('day').toDate()
                                                            );
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                id="dob"
                                                                name={'dob'}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                margin={'normal'}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MaskedTextField
                                                    format="####"
                                                    fullWidth
                                                    id="last4"
                                                    name={'last4'}
                                                    InputLabelProps={{ shrink: true }}
                                                    label={'Last 4 of SSN'}
                                                    margin={'normal'}
                                                    value={last4}
                                                    onChange={handleChangeLast4}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    label={'Address'}
                                                    margin={'normal'}
                                                    value={address}
                                                    onChange={handleChangeAddress}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    InputLabelProps={{ shrink: true }}
                                                    label={'City'}
                                                    margin={'none'}
                                                    value={city}
                                                    onChange={handleChangeCity}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                {/* @ts-ignore*/}
                                                <Select fullWidth value={region} onChange={handleChangeRegion}>
                                                    {USRegions.map((x: any) => {
                                                        return (
                                                            <MenuItem key={x.shortCode} value={x.shortCode}>
                                                                {x.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    label={'Postal Code'}
                                                    margin={'normal'}
                                                    value={postalCode}
                                                    onChange={handleChangePostalCode}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                        <Button
                            color="primary"
                            size="large"
                            fullWidth
                            variant="contained"
                            onClick={handleVerifyIdentity}
                            disabled={!isFormValid}
                        >
                            {actionInProgress ? <CircularProgress size={26} /> : step === 1 ? 'Continue' : 'Confirm'}
                        </Button>
                    </DialogContent>
                </Paper>
            )}
            {(identityCheckError || identityError || eligibilityError) && (
                <Dialog open={Boolean(identityCheckError) || Boolean(identityError) || eligibilityError}>
                    <DialogTitle>Validation Failed</DialogTitle>
                    <DialogContent>
                        {identityError || eligibilityError || wrongDataConfirmed || verificationsAttempts > 2 ? (
                            <>
                                We are sorry. Our system could not verify your information. Please contact customer support
                                support@lifeinfoapp.com
                            </>
                        ) : (
                            <>
                                {step === 2 && (
                                    <>
                                        <Typography>
                                            <strong>Legal First Name:</strong> {firstName}
                                        </Typography>

                                        <Typography>
                                            <strong>Legal last Name:</strong> {lastName}
                                        </Typography>
                                    </>
                                )}
                                <Typography>
                                    <strong>Date of Birth:</strong> {formatDOB(dob)}
                                </Typography>

                                <Typography>
                                    <strong>Last 4 of SNN:</strong> {last4}
                                </Typography>
                                {step === 2 && (
                                    <>
                                        <Typography>
                                            <strong>Address:</strong> {address}
                                        </Typography>

                                        <Typography>
                                            <strong>City:</strong> {city}
                                        </Typography>

                                        <Typography>
                                            <strong>Region:</strong> {region}
                                        </Typography>

                                        <Typography>
                                            <strong>Postal code:</strong> {postalCode}
                                        </Typography>
                                    </>
                                )}

                                <Typography sx={{ marginTop: 20 }}>Is this correct?</Typography>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {eligibilityError || wrongDataConfirmed || verificationsAttempts > 2 ? (
                            <Button variant="contained" color="primary" onClick={handleOpenHelpPage}>
                                OK
                            </Button>
                        ) : (
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => {
                                        setWrongDataConfirmed(true);
                                    }}
                                >
                                    Yes, it is correct
                                </Button>
                                <Button variant="contained" color="secondary" onClick={resetIdentityError}>
                                    No, I need to make changes
                                </Button>
                            </>
                        )}
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
});
