import React from 'react';
import { Box, Checkbox, Divider, Grid, Link, MenuItem, Paper, Select, styled, Typography } from '@mui/material';
import { CheckBox } from '@mui/icons-material';

const Wrapper = styled(Paper)(({ theme }) => ({
    borderRadius: '10px',
    padding: '14px',
    marginTop: '20px'
}));

const Row = (props: any) => (
    <Grid container sx={{ margin: '1px 0px', marginBottom: props.last && '0px', alignItems: 'center' }}>
        <Grid xs={6} item>
            {props.left}
        </Grid>
        <Grid xs={6} item container justifyContent={"flex-end"}>
            {props.right}
        </Grid>
    </Grid>
)

const LeftSideText = styled(Typography)(({ theme }) => ({
    fontSize: '1.1rem'
}));

const RightSideText = styled(Typography)(({ theme }) => ({
    fontSize: '1.1rem',
    fontWeight: 'bold'
}));

const TransactionInfo = () => {
    return (
        <Wrapper>
            <Grid container>
                <Row
                    left={
                        <Typography>Gift Card Amount:</Typography>
                    }
                    right={
                        <Box>
                            <Box>
                                <Typography fontSize={18} textAlign="right">$100.00</Typography>
                            </Box>
                            <Box>
                                <Typography fontSize={10} textAlign="right">USD</Typography>
                            </Box>
                        </Box>
                    }
                />
                <Divider sx={{ color: 'white', width: '100%', margin: '14px 0' }} />
                <Grid container item>
                    <Grid item xs={6} sx={{ borderRight: '1px solid white' }}>
                        <Typography fontWeight={'bold'}>You Earn:</Typography>
                        <Box display="flex" sx={{ justifyContent: 'flex-end', alignItems: 'center' }} >
                            <Box sx={{ textAlign: 'right', marginRight: '14px' }}>
                                <Typography sx={{ fontSize: '20px', color: 'green', fontWeight: 'bold' }}>500</Typography>
                                <Typography variant="body2" fontSize="10px">Sparks</Typography>
                            </Box>
                            <Typography fontSize="20px" marginRight={1}>✨</Typography>
                        </Box>

                    </Grid>
                    <Grid item xs={6} sx={{ paddingLeft: '10px' }}>
                        <Typography fontWeight={'bold'}>You Pay:</Typography>
                        <Box sx={{ textAlign: 'right' }}>
                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>$99.00</Typography>
                            <Typography variant="body2" fontSize="10px">USD</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Wrapper>
    )
}

export default TransactionInfo;