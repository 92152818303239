/* eslint-disable no-undef */
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';

let instance;

class i18nInit {
    static async init() {
        instance = i18n.use(HttpApi);

        await instance.init({
            react: {
                useSuspense: false,
            },
            lng: window.localStorage.getItem('language') || 'en',
            fallbackLng: 'en',
            // load: "languageOnly",
            ns: ['global', 'shoppingBoss'],
            defaultNS: 'global',
            backend: {
                loadPath: `${process.env.PUBLIC_URL}/_language/{{lng}}/{{ns}}.json`,
            },
            debug: false,
        });

        return instance;
    }
}

export default i18nInit;
