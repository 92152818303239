import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const StyledButton = styled(Button)`
    background: linear-gradient(91.26deg, #7ba4f7 0%, #82e6d6 85.28%);
    border-radius: 80px;
`;

export default StyledButton;
