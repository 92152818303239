import CommonStore from './commonStore';
import AuthStore from './authStore';
import GiftcardStore from './giftcardStore';

const commonStore = new CommonStore();
const authStore = new AuthStore(commonStore);

const stores = {
    authStore,
    commonStore,
    giftcardStore: new GiftcardStore(),
};

export default stores;
