import React, { SyntheticEvent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Alert,
    DialogContent,
    DialogTitle,
    FormGroup,
    Paper,
    CircularProgress,
} from '@mui/material';
import { useStores } from '../hooks/use-stores';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import GradientButton from '../components/GradientButton';
import Button from '../components/Button';

export default observer(function SignIn() {
    const { authStore } = useStores();
    const { isAuthenticated, loginError, inProgress, baseLoad } = authStore;
    let navigate = useNavigate();
    let location = useLocation();
    let redirect = new URLSearchParams(location.search).get('redirect');

    useEffect(() => {
        authStore.setError('');
    }, []);

    const validateUser = async (e?: SyntheticEvent) => {
        if (inProgress || baseLoad) return;
        authStore.setError('');
        let result = await authStore.signIn();
        if (result) {
            navigate(redirect || '/', { replace: true });
        }
    };
    if (isAuthenticated) return <Navigate to={redirect || '/'} />;

    return (
        <div
            style={{
                color: '#575962',
                height: '100%',
                width: '100%',
                outline: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
            }}
        >
            {!process.env.REACT_APP_DEV_USER ? (
                <>
                    You do not have permission to access this resource, please
                    contact support@sparkwallet.io
                </>
            ) : (
                <Paper
                    sx={{
                        margin: 2,
                        width: 390,
                        maxWidth: '98%',
                    }}
                >
                    <DialogTitle
                        sx={(theme) => ({
                            paddingTop: theme.spacing(6),
                            paddingBottom: theme.spacing(1),
                            textAlign: 'center',
                            color: '#575962',
                        })}
                    >
                        Please Login{' '}
                    </DialogTitle>
                    <DialogContent
                        sx={(theme) => ({
                            padding: theme.spacing(4),
                            paddingTop: 0,
                        })}
                    >
                        {loginError && (
                            <Alert severity="error">{loginError}</Alert>
                        )}

                        <form>
                            <FormGroup>
                                <div>
                                    <GradientButton
                                        color="secondary"
                                        size="large"
                                        fullWidth
                                        variant="contained"
                                        onClick={validateUser}
                                    >
                                        {inProgress || baseLoad ? (
                                            <CircularProgress size={26} />
                                        ) : (
                                            'Login'
                                        )}
                                    </GradientButton>
                                </div>
                            </FormGroup>
                            <div>OR</div>
                            <FormGroup>
                                <div>
                                    <Button
                                        color="secondary"
                                        size="large"
                                        fullWidth
                                        variant="contained"
                                        href={'/signup'}
                                    >
                                        {inProgress || baseLoad ? (
                                            <CircularProgress size={26} />
                                        ) : (
                                            'Create New Account'
                                        )}
                                    </Button>
                                </div>
                            </FormGroup>
                        </form>
                    </DialogContent>
                </Paper>
            )}
        </div>
    );
});
