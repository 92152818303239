import BaseService from './BaseService';
import { AxiosRequestConfig } from 'axios';

class IdLessRestService extends BaseService {
    create(entity: any, options?: AxiosRequestConfig) {
        return this.post(this.baseUrl, entity, options);
    }

    fetch(suffix?: string) {
        if (suffix) return this.get(`${this.baseUrl}/${suffix}`);
        return this.get(`${this.baseUrl}`);
    }

    list(params?: any) {
        return this.get(`${this.baseUrl}`, params);
    }

    update(entity: any, suffix?: string) {
        if (suffix) return this.put(`${this.baseUrl}/${suffix}`, entity);
        return this.put(`${this.baseUrl}`, entity);
    }

    delete(params?: any) {
        return this.del(`${this.baseUrl}`, params);
    }
}

export default IdLessRestService;
