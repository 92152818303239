import { isObservableArray } from 'mobx';
import moment from 'moment';

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function backOffDelay(baseDelay, counter, maxDelay = 240000, factor = 2) {
    const jitter = 1 + getRandomArbitrary(0, 0.01);

    let delay = Math.min(baseDelay * Math.exp(counter / factor), maxDelay);
    delay = Math.round(delay * jitter);
    //console.log( "delay:",  delay, counter,jitter )
    return delay;
}

export function getUniqKey(item) {
    return (
        item.tag ||
        item.title
            .replace(/\//g, '_')
            .replace(/\s+/g, '_')
            .replace(/[^a-zA-Z0-9]/g, '_')
            .replace(/_+/g, '_')
            .toLowerCase()
    );
}

export function extractErrorMessage(error) {
    if (error === false || error === true || error == null) return;
    if (typeof error === 'string') return error;
    let responseData = error.response && error.response.data && error.response.data;

    if (responseData && responseData.errors && Array.isArray(responseData.errors)) {
        return responseData.errors.map((e) => e.messages.join(';')).join('\n');
    }
    if (responseData && responseData.message) {
        return responseData.message;
    }

    return (responseData && responseData.error) || error.message;
}

export function joinPath(...parts) {
    if (!parts) return '';
    let result = '';
    for (let i = 0; i < parts.length; i++) {
        result += parts[i].toString().startsWith('/') && i > 0 ? parts[i].substr(1) : parts[i];

        if (i !== parts.length - 1 && !result.endsWith('/')) result += '/';
    }

    return result;
}

export function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );

    return JSON.parse(jsonPayload);
}

export function cardLogo(cardType) {
    let imgCardLogo = '';

    if (cardType.toLowerCase().includes('visa')) {
        imgCardLogo = 'visa';
    } else if (cardType.toLowerCase().includes('mastercard')) {
        imgCardLogo = 'mastercard';
    } else if (cardType.toLowerCase().includes('amex')) {
        imgCardLogo = 'amex';
    } else if (cardType.toLowerCase().includes('discover')) {
        imgCardLogo = 'discover';
    }

    return imgCardLogo ? `https://main.secure.footprint.net/public/logo/${imgCardLogo}.jpg` : null;
}

export function isArray(value) {
    return Array.isArray(value) || isObservableArray(value);
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function currencyFormatter(value) {
    if (isNaN(Number(value))) {
        return '0';
    }

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return `${currencyFormatter.format((Number(value) * 100) / 100)}`;
}

export function formatDOB(dob) {
    return moment(dob).format("MM/DD/YYYY");
    // return `${dob.getFullYear()}-${(dob.getMonth() + 1).toString().padStart(2, '0')}-${dob
    //     .getDate()
    //     .toString()
    //     .padStart(2, '0')}`;
}
