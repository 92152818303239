import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

const SmallAvatar = styled(Avatar)`
    width: 30px;
    height: 30px;
`;

export default SmallAvatar;
