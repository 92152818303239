import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { useStores } from '../../../hooks/use-stores';
import { capitalizeFirstLetter } from '../../../utils/helpers';
import Merchant from '../../../stores/models/Merchant';

const MerchantImage = styled('img')(({ theme }) => ({
    backgroundSize: 'contain',
    width: '100%',
    borderRadius: '14px',
    marginBottom: '8px',
    [theme.breakpoints.up('sm')]: {
        fontSize: 18,
    },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        transform: 'scale(1) translate(0%, -50%)',
        backgroundColor: 'red',
        color: 'white',
        fontSize: '14px',
        borderRadius: '4px',
        fontWeight: 'bold'
    },
}));

const Category = () => {
    const { giftcardStore } = useStores();
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/giftcards');
    }

    const onSelectMerchant = (merchant: Merchant) => {
        giftcardStore.selectedMerchant = merchant;
        navigate('/giftcards/review');
    }

    if (!giftcardStore.category || giftcardStore.merchantsOfSelectedCategory?.length == 0) {
        goHome();
        return null;
    }

    return (
        <Container sx={{ padding: 0 }}>
            <Button sx={{ textTransform: 'none', display: 'flex', alignItems: 'center' }} onClick={goHome} >
                <Typography variant="body1" fontWeight="bold" color={'white'}>&lt; Gift Cards List</Typography>
            </Button>
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                {capitalizeFirstLetter(giftcardStore.category)}
            </Typography>
            <Grid container spacing={2}>
                {
                    giftcardStore.merchantsOfSelectedCategory.map(merchant =>
                        <Grid item xs={6} onClick={() => onSelectMerchant(merchant)} sx={{ cursor: 'pointer' }}>
                            <StyledBadge badgeContent={`${merchant.cashbackPercentage}% Rewards`}>
                                <MerchantImage src={merchant.imageUrl} />
                            </StyledBadge>
                            <Typography variant="body1" sx={{ textAlign: 'center' }}>{merchant.name}</Typography>
                        </Grid>
                    )
                }
            </Grid>
        </Container>
    )
}

export default Category;