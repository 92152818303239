import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const SparkButton = styled(Button)`
box-shadow: 0 20px 30px -10px rgba(54, 54, 54, 0.6);
padding: 12px 30px 10px;
letter-spacing: -0.5px;
display: inline-block;
border-radius: 100px;
font-family: "colfax-medium", sans-serif;
-webkit-transition: all 0.4s ease;
transition: all 0.4s ease;
text-align: center;
position: relative;
background: linear-gradient(91.26deg, #8AD4EC 0%, #EF96FF 21.74%, #FF56A9 54.03%, #FFAA6C 85.28%);
cursor: pointer;
color: #fff;
width: 100%;
z-index: 2;
text-transform: none;
}
`;

export default SparkButton;
