import IdLessRestService from './IdLessRestService';
import BaseService from './BaseService';

export default class PasswordService extends IdLessRestService {
    constructor(parentService: BaseService) {
        if (parentService) {
            super(`${parentService.baseUrl}/password`);
        } else throw new Error('Can be only nested service');
    }
}
