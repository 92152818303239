import RestService from "./RestService";



export default class Web extends RestService {
    constructor() {
        super('/api/lifeapp/web' )
    }

}
