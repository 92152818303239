import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import {
    Box,
    BoxProps,
    Container,
    Chip,
    Dialog,
    Typography,
    Button,
    Grid,
    MenuItem,
    Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../hooks/use-stores';
import countries from '../../../utils/countries';
import { useNavigate } from 'react-router-dom';
import Badge, { BadgeProps } from '@mui/material/Badge';
import FeaturedMerchants from './components/FeaturedMerchants';
import Merchant from '../../../stores/models/Merchant';
import Search from '../../../components/Search';

const Root = styled((props: BoxProps) => (
    <Box display="flex" flexWrap="nowrap" flexDirection="column" {...props} />
))(({ theme }) => ({
    overflowX: 'auto',
    marginTop: theme.spacing(1),
    gap: 5,
}));

const FiltersBox = styled((props: BoxProps) => (
    <Box display="flex" {...props} />
))(({ theme }) => ({
    gap: 12,
    overflowX: 'scroll',
    marginTop: '6px'
}));

const CountryLabel = styled('span')(({ theme }) => ({
    fontSize: 14,
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
        fontSize: 18,
    },
}));

const CountryFlag = styled('span')(({ theme }) => ({
    verticalAlign: 'middle',
    fontSize: 20,
    [theme.breakpoints.up('sm')]: {
        fontSize: 30,
    },
}));

const MerchantImage = styled('img')(({ theme }) => ({
    backgroundSize: 'contain',
    width: '100%',
    borderRadius: '14px',
    [theme.breakpoints.up('sm')]: {
        fontSize: 18,
    },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        transform: 'scale(1) translate(20%, 50%)',
        backgroundColor: 'red',
        color: 'white',
        fontSize: '14px',
        borderRadius: '4px',
        fontWeight: 'bold'
    },
}));

const Merchants = () => {
    const { t } = useTranslation();
    const { giftcardStore } = useStores();
    const navigate = useNavigate();

    const {
        filters,
        merchants,
        countryCode,
        countryName,
        filteredMerchants
    } = giftcardStore;
    const [modalOpen, setModalOpen] = useState(false);

    const onSearchQueryUpdated = (event: ChangeEvent<HTMLInputElement>) => {
        giftcardStore.searchQuery = event.target.value;
    };

    const closeModal = useCallback(() => {
        setModalOpen(false);
    }, [])

    const switchCountry = useCallback((countryCode: string) => {
        giftcardStore.switchCountry(countryCode);
    }, [countryCode]);

    const getCountryFlag = useCallback((countryCode: string) => {
        let country = countries.find((country) => country.code == countryCode);
        if (country) {
            return country.flag;
        }
        return '';
    }, [countryCode]);

    const onSelectMerchant = (merchant: Merchant) => {
        giftcardStore.selectedMerchant = merchant;
        navigate('/giftcards/review');
    }

    const goToCategory = (category: string) => {
        giftcardStore.category = category;
        navigate('/giftcards/category');
    }

    useEffect(() => {
        giftcardStore.loadMerchants();
    }, []);

    return (
        <Root>
            <Dialog open={modalOpen} onClose={closeModal}>
                <Typography>
                    {t('shoppingBoss:merchants.viewingMerchants', {
                        countryName,
                    })}{' '}
                    <strong>
                        {t('shoppingBoss:merchants.redeemableIn', {
                            countryName,
                        })}
                    </strong>
                </Typography>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={closeModal}
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                >
                    {t('shoppingBoss:ok')}
                </Button>
            </Dialog>
            <Grid container xs={12}>
                {merchants.featured &&
                    <Grid item xs={12}>
                        <FeaturedMerchants
                            title="Featured Merchants"
                            subtitle="Ready to use online or in-store"
                            category="featured"
                            merchants={giftcardStore.merchants.featured}
                            allowSearch
                            largeDesktopImages
                        />
                    </Grid>
                }
                {/* This is a sample bc we don't yet have an api that will return this */}
                {
                    <Grid item xs={12}>
                        <FeaturedMerchants
                            title="Hungry?"
                            subtitle="Get your groceries or food delivered"
                            category={'food'}
                            merchants={giftcardStore.merchantsByCategory('food').slice(0, 4)} // this should be changed
                            allowSeeAll
                        />
                    </Grid>
                }
            </Grid>
            <Box>
                <Grid container xs={12}>
                    <Grid container item xs={8} sx={{ marginBottom: '10px' }}>
                        <Box display={'flex'} flexDirection="column">
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Search Merchants</Typography>
                            <Typography variant="body1" sx={{ color: 'gray' }}>Not seeing what you're looking for?</Typography>
                        </Box>
                    </Grid>
                    <Grid container item xs={4} justifyContent="flex-end">
                        <Select
                            variant="standard"
                            value={countryCode}
                            renderValue={(value) => (
                                <span>
                                    <CountryFlag>
                                        {getCountryFlag(value)}
                                    </CountryFlag>
                                    <CountryLabel>{countryCode}</CountryLabel>
                                </span>
                            )}
                            onChange={(e) => switchCountry(e.target.value)}
                            disableUnderline
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                        >
                            {countries.map((country) => (
                                <MenuItem key={country.code} value={country.code}>
                                    <CountryFlag>{country.flag}</CountryFlag>
                                    <CountryLabel>{country.code}</CountryLabel>
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Search searchQuery={giftcardStore.searchQuery} onSearchQueryChanged={onSearchQueryUpdated} />
                <FiltersBox>
                    {filters.map((f) => (
                        <Chip
                            key={f}
                            clickable
                            style={{ textTransform: 'capitalize' }}
                            label={f}
                            onClick={() => goToCategory(f)}
                        />
                    ))}
                </FiltersBox>
                <Container sx={{ marginTop: '16px', padding: '0px !important' }}>
                    <Grid container spacing={2}>
                        {filteredMerchants.map((merchant) => {
                            return (
                                <Grid item xs={6} key={merchant.slug} onClick={() => onSelectMerchant(merchant)}>
                                    <StyledBadge anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }} badgeContent={`${merchant.cashbackPercentage}% Rewards`}>
                                        <MerchantImage src={merchant.imageUrl} alt={merchant.name} />
                                    </StyledBadge>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Container>
            </Box>
        </Root>
    );
}

export default observer(Merchants);
