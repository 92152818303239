import React, { SyntheticEvent, useEffect } from 'react';
//@ts-ignore
import Helmet from 'react-helmet';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useStores } from './hooks/use-stores';
import {
    Outlet,
    Route,
    Routes,
    //Navigate,
    useNavigate,
    useMatch,
} from 'react-router-dom';
import RequireAuth from './hocs/RequireAuth';
import LoginPage from './pages/LoginPage';
import { observer } from 'mobx-react';
import {
    AppBar,
    Avatar,
    Box,
    Container,
    CssBaseline,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Menu,
    Toolbar,
} from '@mui/material';
import User from './stores/models/User';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SmallAvatar from './components/SmallAvatar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CreateAccount from './pages/CreateAccount';
import ContinueAuth from './pages/ContinueAuth';
import Giftcards from './pages/Giftcards';
import { useLocation } from 'react-router';
import PhoneVerification from './pages/PhoneVerification';
import IdentityVerification from './pages/IdentityVerification';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#007BFF',
            // dark: will be calculated from palette.primary.main,
            contrastText: '#FFFFFF',
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#dc004e',
            // dark: will be calculated from palette.primary.main,
            contrastText: '#FFFFFF',
        },
    },
});

const SparkVerifyHead = () => (
    <Helmet>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <meta name="description" content="Phone Verification" />
        <title>Spark Wallet</title>
    </Helmet>
);

const SparkHead = () => (
    <Helmet>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <meta name="description" content="Spark Wallet" />
        <title>Spark Wallet</title>
    </Helmet>
);

function Layout({ currentUser }: { currentUser: User | null }) {
    const { t } = useTranslation();
    const { authStore } = useStores();
    const navigate = useNavigate();
    const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleUserMenuOpen = (event: SyntheticEvent<HTMLElement>) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    const handleLogout = () => {
        authStore.logout();
        handleUserMenuClose();
        navigate('/');
    };

    const isUserMenuOpen = Boolean(userMenuAnchorEl);

    const hideToolbar = !process.env.REACT_APP_DEV_TOOLBAR;

    return hideToolbar ? (
        <Outlet />
    ) : (
        <div>
            <AppBar color={'secondary'} position="static">
                <Container maxWidth={'md'}>
                    <Toolbar disableGutters variant="dense">
                        <span>
                            <img src={'/logo.png'} />
                            <span>Life</span>
                        </span>
                        <Box flexGrow={1}></Box>
                        {authStore.isAuthenticated && (
                            <div>
                                <IconButton color="inherit" onClick={handleUserMenuOpen}>
                                    <SmallAvatar src={currentUser?.photoURL} variant={'square'} />
                                </IconButton>
                            </div>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
            <Menu
                anchorEl={userMenuAnchorEl}
                id={'user-menu'}
                keepMounted
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={isUserMenuOpen}
                onClose={handleUserMenuClose}
            >
                <div>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar src={currentUser?.photoURL} variant={'square'} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${currentUser?.firstName} ${currentUser?.lastName}`}
                            secondary={
                                <>
                                    {currentUser?.username} <br /> {currentUser?.userID}{' '}
                                </>
                            }
                        ></ListItemText>
                    </ListItem>
                </div>
                <ListItem button onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('Logout')} />
                </ListItem>
            </Menu>
            <Outlet />
        </div>
    );
}

function App() {
    const { commonStore, authStore } = useStores();
    const { languagesLoaded, appLoaded } = commonStore;
    const location = useLocation();
    const isFinalize = useMatch('/finalize/*');
    const isMobile = useMatch('/mobile/*');
    const isRegistrationFinalize = useMatch('/registration/finalize/*');
    const isRegistrationMobile = useMatch('/registration/mobile/*');
    const searchParams = new URLSearchParams(location.search);
    const vfp = searchParams.get('vfp');
    const haveVFP = Boolean(vfp);

    React.useMemo(() => {
        if (commonStore.onVerify != null) return commonStore.onVerify;
        let onVerify =
            location.pathname.includes('/verify_identity') ||
            location.pathname.includes('/verify_phone') ||
            location.pathname.includes('/finalize') ||
            location.pathname.includes('/mobile') ||
            location.pathname.includes('/registration/finalize') ||
            location.pathname.includes('/registration/mobile');
        commonStore.onVerify = onVerify || null;
        return onVerify;
    }, [location.pathname, commonStore.onVerify]);

    useEffect(() => {
        if (haveVFP) {
            if (!!isFinalize || !!isMobile || !!isRegistrationFinalize || !!isRegistrationMobile) {
            } else {
                //@ts-ignore
                window.location = `${process.env.REACT_APP_CONTINUE_AUTH_URL}?vfp=${vfp}`;
            }
        }
    }, [haveVFP, vfp, isFinalize]);

    useEffect(() => {
        (async () => {
            try {
                await commonStore.loadLanguages();
                await authStore.pullUser();
            } catch (e) {
                console.error(e);
            } finally {
                await commonStore.setAppLoaded();
            }
        })();
    }, []);

    if (!languagesLoaded) {
        return <div>{languagesLoaded}</div>;
    }

    return (
        <>
            {commonStore.onVerify ? <SparkVerifyHead /> : <SparkHead />}
            <I18nextProvider i18n={commonStore.i18n}>
                <ThemeProvider theme={darkTheme}>
                    <CssBaseline />
                    {languagesLoaded}
                    {appLoaded && (
                        <Routes>
                            {haveVFP && <Route index element={<ContinueAuth vfp={vfp} />} />}
                            {haveVFP && isFinalize && (
                                <Route path="/finalize/:userAuthGuid" element={<ContinueAuth finalize vfp={vfp} />} />
                            )}
                            {haveVFP && isMobile && (
                                <Route path="/mobile/:userAuthGuid" element={<ContinueAuth mobile vfp={vfp} />} />
                            )}
                            {haveVFP && isRegistrationFinalize && (
                                <Route
                                    path="/registration/finalize/:userAuthGuid"
                                    element={<ContinueAuth registration={true} finalize vfp={vfp} />}
                                />
                            )}
                            {haveVFP && isRegistrationMobile && (
                                <Route
                                    path="/registration/mobile/:userAuthGuid"
                                    element={<ContinueAuth registration={true} mobile vfp={vfp} />}
                                />
                            )}
                            <Route element={<Layout currentUser={authStore.currentUser} />}>
                                <Route
                                    path="/verify_identity"
                                    element={
                                        <RequireAuth>
                                            <IdentityVerification />
                                        </RequireAuth>
                                    }
                                />{' '}
                                <Route
                                    path="/verify_phone"
                                    element={
                                        <RequireAuth>
                                            <PhoneVerification />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="/somepage"
                                    element={
                                        <RequireAuth>
                                            <div>somepage</div>
                                        </RequireAuth>
                                    }
                                />
                                <Route path="/signin" element={<LoginPage />} />
                                <Route path="/signup" element={<CreateAccount />} />
                                <Route path="/public" element={<div>Public Page</div>} />
                                <Route index element={<div>Home Page</div>} />
                                <Route
                                    path="/giftcards/*"
                                    element={
                                        <RequireAuth>
                                            <Giftcards />
                                        </RequireAuth>
                                    }
                                />
                            </Route>
                        </Routes>
                    )}
                </ThemeProvider>
            </I18nextProvider>
        </>
    );
}

export default observer(App);
