import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Alert,
    DialogContent,
    DialogTitle,
    FormGroup,
    Paper,
    Box,
    Typography,
} from '@mui/material';
import { useStores } from '../hooks/use-stores';
import { Navigate } from 'react-router-dom';
import GradientButton from '../components/GradientButton';
import { MuiTelInput } from 'mui-tel-input';

export default observer(function SignUp() {
    const { authStore } = useStores();
    const { isAuthenticated, loginError, authResult } = authStore;
    const [phone, setPhone] = React.useState<string>('');

    useEffect(() => {
        authStore.setError('');
    }, []);

    const handleOnChange = (value: string, info: any) => {
        console.log(info);
        setPhone(value);
    };

    const handleGetAuthUrl = () => {
        authStore.getAuthUrl(phone);
    };

    if (isAuthenticated) return <Navigate to={'/'} />;

    return (
        <div
            style={{
                color: '#575962',
                height: '100%',
                width: '100%',
                outline: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
            }}
        >
            {!process.env.REACT_APP_DEV_USER ? (
                <>
                    You do not have permission to access this resource, please
                    contact support@sparkwallet.io
                </>
            ) : (
                <Paper
                    sx={{
                        margin: 2,
                        width: 390,
                        maxWidth: '98%',
                        minHeight: 500,
                    }}
                >
                    {authResult?.redirectUrl ? (
                        <>
                            {' '}
                            <DialogTitle
                                sx={(theme) => ({
                                    paddingTop: theme.spacing(6),
                                    paddingBottom: theme.spacing(1),
                                    textAlign: 'center',
                                    color: '#575962',
                                })}
                            >
                                !DEBUG!: confirm url
                            </DialogTitle>
                            <DialogContent
                                sx={(theme) => ({
                                    heigh: '100%',
                                    padding: theme.spacing(4),
                                    paddingTop: 0,
                                })}
                            >
                                <GradientButton
                                    color="secondary"
                                    size="large"
                                    fullWidth
                                    variant="contained"
                                    href={authResult.redirectUrl}
                                >
                                    'Confirm'
                                </GradientButton>
                            </DialogContent>
                        </>
                    ) : (
                        <>
                            <DialogTitle
                                sx={(theme) => ({
                                    paddingTop: theme.spacing(6),
                                    paddingBottom: theme.spacing(1),
                                    textAlign: 'center',
                                    color: '#575962',
                                })}
                            >
                                Create New Account
                            </DialogTitle>
                            <DialogContent
                                sx={(theme) => ({
                                    heigh: '100%',
                                    padding: theme.spacing(4),
                                    paddingTop: 0,
                                })}
                            >
                                {loginError && (
                                    <Alert severity="error">{loginError}</Alert>
                                )}

                                <form>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            position: 'relative',
                                        }}
                                    >
                                        <FormGroup>
                                            <Typography>
                                                To create an account enter your
                                                mobile number.
                                            </Typography>
                                            <MuiTelInput
                                                value={phone}
                                                defaultCountry="US"
                                                onChange={handleOnChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <div>
                                                <GradientButton
                                                    color="secondary"
                                                    size="large"
                                                    fullWidth
                                                    variant="contained"
                                                    onClick={handleGetAuthUrl}
                                                >
                                                    'Next'
                                                </GradientButton>
                                            </div>
                                        </FormGroup>
                                    </Box>
                                </form>
                            </DialogContent>
                        </>
                    )}
                </Paper>
            )}
        </div>
    );
});
