import React from "react";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";
import { List, ListItem, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { currencyFormatter } from "../../../../utils/helpers";

const StaticPriceList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
}));

const StaticPriceItem = styled(ListItem)(({ theme }) => ({
    display: 'flex',
    flex: '0 0 25%',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    border: 'solid rgba(245, 0, 87, .5) 1px',
    margin: theme.spacing(1),
    height: 'auto',
    '&::before': {
        content: '""',
        display: 'block',
        paddingTop: '100%',
    }
}));

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef((props, ref) => {
    //@ts-ignore
    const { inputRef, onChange, ...other } = props

    return (
        <NumberFormat
            {...other}
            style={{
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                fontSize: 40,
                padding: '6px',
                letterSpacing: 1,
                color: '#74b9d6'
            }}
            getInputRef={ref}
            decimalScale={2}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                        //@ts-ignore
                        name: other.name,
                    },
                })
            }}
            isNumericString
            thousandSeparator
            prefix="$"
        />
    )
});


interface State {
    numberformat: string;
}

interface Props {
    onPriceSelected: (price: string) => void
}

const FormattedInputs = (props: Props) => {
    const [value, setValue] = React.useState<string>('100');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) return;
        setValue(event.target.value)
        props.onPriceSelected(event.target.value)
    };

    return (
        <div>
            {
                false ?
                    <StaticPriceList>
                        {
                            [].map(
                                price =>
                                    <StaticPriceItem key={price} onClick={() => props.onPriceSelected(price)}>
                                        ${price}
                                    </StaticPriceItem>
                            )
                        }
                    </StaticPriceList>
                    : <>

                        <TextField
                            sx={{
                                width: '100%', marginTop: '20px',
                                border: '2px solid',
                                borderImageSlice: 1,
                                borderImageSource: 'linear-gradient(to left, #80ded9, #7ba7f5)',
                            }}
                            value={value}
                            onChange={handleChange}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            InputProps={{
                                inputComponent: NumberFormatCustom as any
                            }}
                            variant="outlined"
                        />
                        <Typography variant="body2" color="gray" sx={{ marginTop: '4px' }} textAlign={'center'}>Amount must be between X and X</Typography>
                    </>
            }
        </div>
    );
}

export default FormattedInputs;