import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, styled, Typography } from '@mui/material';
import AmountInput from './components/AmountInput';
import GiftCardImage from './components/GiftCardImage';
import SparkButton from '../../../components/SparkButton';
import { useStores } from '../../../hooks/use-stores';

const CheckoutContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center'
}));

const CustomBadge = styled('span')(({ theme }) => ({
    width: 'max-content',
    padding: '0px 8px',
    backgroundColor: 'red',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '4px'
}));

const Checkout = () => {
    const { giftcardStore } = useStores();
    const {
        selectedMerchant
    } = giftcardStore;

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [error, setError] = useState<string>('')

    const navigate = useNavigate();

    const goHome = () => {
        navigate('/giftcards');
    }

    const isValidPrice = () => {
        // TODO if static, check static is in list of static prices
        // else check if is in range provided by api
        return true;
    }

    const completePurchase = () => {
        if (isValidPrice()) {
            try {
                // Do auth token stuff here before moving on to last page
                navigate('/giftcards/checkout');
            } catch (e) {
                setError('Error');
            }
        }
    }

    const onPriceSelected = (price: string) => {
        giftcardStore.orderTotal = Number(price);
    }

    useEffect(() => {
        if (!giftcardStore.selectedMerchant) {
            goHome();
        }
    }, [])

    return (
        <>
            <Grid container alignItems={'center'}>
                <Grid item xs={6}>
                    <Button sx={{ textTransform: 'none' }} onClick={goHome}>
                        <Typography variant="body1" fontWeight="bold" color={'white'}>&lt; Gift Cards List</Typography>
                    </Button>
                </Grid>
                <Grid item xs={6} container justifyContent={'flex-end'}>
                    <CustomBadge>{selectedMerchant?.cashbackPercentage || 0}% Rewards</CustomBadge>
                </Grid>
            </Grid>
            <CheckoutContainer>
                <Typography sx={{marginTop: 2, marginBottom: 1}} variant="body1"><b>{selectedMerchant?.name}</b></Typography>
                <GiftCardImage
                    backgroundColor={'white'}
                    logo={selectedMerchant?.imageUrl || ''}
                />
                <AmountInput onPriceSelected={onPriceSelected} />
                <Box sx={{position: 'absolute', bottom: 40, width: '80%', maxWidth: '500px'}}>
                    <SparkButton disabled={false} onClick={completePurchase}>Review &amp; Pay</SparkButton>
                </Box>
            </CheckoutContainer>
        </>
    )
};

export default Checkout;