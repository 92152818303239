import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { Button, Checkbox, Grid, Paper, Typography } from '@mui/material';
import GiftCardImage from '../Review/components/GiftCardImage';
import { useStores } from '../../../hooks/use-stores';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import TransactionInfo from '../Review/components/TransactionInfo';
import SparkButton from '../../../components/SparkButton';
import QRCode from 'easyqrcodejs';
import { collection, query, where, onSnapshot, getFirestore } from "firebase/firestore";
import { initializeApp } from 'firebase/app';
import { currencyFormatter } from '../../../utils/helpers';

const Row = (props: any) => (
    <Paper sx={{ padding: .7, borderRadius: 3 }}>
        <Grid container
            sx={{ margin: '7px 0px', marginBottom: props.last && '0px', alignItems: 'center' }}>
            <Grid xs={10} item>
                {props.left}
            </Grid>
            <Grid xs={2} item container justifyContent={"flex-end"}>
                {props.right}
            </Grid>
        </Grid>
    </Paper>
)

const QrWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    '& > canvas': {
        margin: '0 auto',
        marginTop: 10
    }
}));

const LeftSideText = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 'bold'
}));

const DEFAULT_QR_OPTIONS = {
    logo: `${process.env.PUBLIC_URL}/img/sparklogo.png`,
    logoWidth: 50,
    logoHeight: 50,
    logoBackgroundTransparent: true,
    text: "some random text",
    width: 200,
    height: 200,
    colorDark: "#000000",
    colorLight: "#ffffff",
    correctLevel: QRCode.CorrectLevel.H,
    quietZone: 10,
    quietZoneColor: "rgba(255,255,255,1)",
};

const LIGHTNING_PAYMENT_COLLECTION = 'testqrcodes';

const Checkout = () => {
    const { authStore, giftcardStore } = useStores();
    const qrcode = React.createRef<HTMLDivElement>();
    const {
        selectedMerchant
    } = giftcardStore;

    let unsub: () => void;

    const [generatedCode, setGeneratedCode] = useState<boolean>(false);
    const [appliedPoints, setAppliedPoints] = useState<boolean>(false);

    const navigate = useNavigate();

    const goHome = useCallback(() => {
        navigate('/giftcards');
    }, []);

    const useLightning = async () => {
        try {
            if (generatedCode) {
                return;
            }
            const options = { ...DEFAULT_QR_OPTIONS, text: 'tbd qrcode text from api' }
            // Create QRCode Object
            new QRCode(qrcode.current, options);
            setGeneratedCode(true);

            // TODO api call here for doc id
            const docIdToListenFor = 'someid' // await SERVICE.DOC

            // Start listening on collection
            startFirebaseListener(docIdToListenFor);
        } catch (e) {
            alert(e)
        }
    }

    const startFirebaseListener = (listenForDocument: string) => {
        if (!authStore.app) {
            // This is an error case that should probably be handled in init in useeffect
            return;
        }
        const db = getFirestore(authStore.app);

        unsub = onSnapshot(collection(db, LIGHTNING_PAYMENT_COLLECTION), (querySnapshot) => {
            // If you're here, then the snapshot was triggered, ie the document we are listneing to was added to the collection
            // you can querySnapshot.forEach(doc => doc.data().memberVar) to got the doc data if you need to do more
            // otherwise, you are allowed to move on as the payment was successful
            console.log('success')
            // if listenForDocument
            // navigate('')
        }, (error) => {
            console.log(error)
        });
    }

    // Will come from api - associate methods 
    const paymentMethods = [
        {
            icon: '⚡',
            name: 'Bitcoin Lightning',
            balance: '.00387 BTC',
            onClick: useLightning
        }
    ]

    useEffect(() => {
        if (!giftcardStore.selectedMerchant) {
            goHome();
        }

        return (() => {
            if (unsub) {
                unsub();
            }
        })
    }, [])

    return (<>
        <Grid container alignItems={'center'}>
            <Grid item xs={12}>
                <Button sx={{ textTransform: 'none' }} onClick={goHome}>
                    <Typography variant="body1" fontWeight="bold" color={'white'}>&lt; Gift Cards List</Typography>
                </Button>
            </Grid>
        </Grid>
        <GiftCardImage
            backgroundColor={'white'}
            logo={selectedMerchant?.imageUrl || ''}
            currency="USD"
            amount={currencyFormatter(giftcardStore.orderTotal)}
        />
        <div style={{ marginTop: '20px' }} />
        <Row
            left={
                <Box display="flex" alignItems={'center'} >
                    <Box sx={{ marginRight: 1, fontSize: 30 }}>
                        ✨
                    </Box>
                    <Box display={'flex'} flexDirection='column'>
                        <LeftSideText>Apply Spark Points</LeftSideText>
                        {appliedPoints && <Typography variant="caption">(X Applied)</Typography>}
                    </Box>
                </Box>
            }
            right={<Checkbox value={appliedPoints} onClick={() => setAppliedPoints(true)} />}
        />
        <Typography sx={{ marginTop: 2, marginBottom: 1 }} variant="body1"><b>Choose Payment Method</b></Typography>
        <Box>
            {
                paymentMethods.map(method =>
                    <Paper onClick={useLightning} sx={{ cursor: 'pointer', padding: .7, borderRadius: 3, backgroundColor: '#707070' }}>
                        <Box display="flex" alignItems="center" sx={{ margin: '7px 0px', }}>
                            <Box sx={{ marginRight: 1, fontSize: 30 }}>
                                {method.icon}
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <LeftSideText fontWeight="bold">{method.name}</LeftSideText>
                                <Typography variant="caption">{method.balance}</Typography>
                            </Box>
                        </Box>
                    </Paper>
                )
            }
        </Box>
        <QrWrapper ref={qrcode} sx={{ minHeight: generatedCode ? '230px' : '0px' }}></QrWrapper>
        <Typography sx={{ marginTop: 2, marginBottom: -1 }} ><b>Order Details</b></Typography >
        <TransactionInfo />
        {false && <>
            <Box display="flex" flexDirection="column" sx={{ marginTop: 2, marginBottom: 1 }}>
                <Box display="flex" alignItems={'center'}>
                    <Checkbox />
                    <Typography sx={{ fontSize: 13 }}>
                        By proceeding, you agree to these <a href="#">Terms and Conditions</a>
                    </Typography>
                </Box>
                <Box sx={{ marginTop: -1, marginBottom: 1 }}>
                    <Typography sx={{ color: 'red', textAlign: 'center', fontSize: 12 }}>
                        Gift card purchases are final and non-refundable
                    </Typography>

                </Box>
            </Box>
            {!generatedCode && <SparkButton><b>Pay</b></SparkButton>}
        </>
        }
    </>
    );
}

export default observer(Checkout);