import * as React from 'react';
import { DialogTitle } from '@mui/material';

function StyledDialogTitle({ children, ...rest }: { children: any }) {
    return (
        <DialogTitle
            sx={(theme) => ({
                paddingTop: {
                    xs: theme.spacing(1),
                    md: theme.spacing(3),
                },
                paddingBottom: theme.spacing(1),
                textAlign: 'center',
                color: '#74b1db',
            })}
            {...rest}
        >
            {children}
        </DialogTitle>
    );
}

export default StyledDialogTitle;
