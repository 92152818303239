import { reaction, makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import { backOffDelay, extractErrorMessage, parseJwt } from '../utils/helpers';
import { setItem, removeItem, getItem } from '../utils/storage';
import CommonStore from './commonStore';
import User from './models/User';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { generateCreateEntity } from '../utils/mobx';
import IProveAuthResponse from '../interfaces/ProveAuth';
import IProveFinalizeResponse from '../interfaces/ProveFinalize';
import IVerificationResultResponse from '../interfaces/ProveVerification';
import IEligibilityResultResponse from '../interfaces/ProveEligibility';
import IIdentityResultResponse from '../interfaces/ProveIdentity';
require('firebase/auth');
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyCUThJATfzI4-fyQkRLuwQ75LvXKtrIWvE',
    authDomain: 'getsparkwallet.firebaseapp.com',
    projectId: 'getsparkwallet',
    storageBucket: 'getsparkwallet.appspot.com',
    messagingSenderId: '598658886124',
    appId: '1:598658886124:web:5b000eb1fa9d8ff8c138c9',
    measurementId: 'G-FKGYE263MV',
};

function getSavedToken(): { accessToken: string; refreshToken: string } | null {
    const accessToken = getItem('spark_access_token') || getItem('access_token');
    const refreshToken = getItem('spark_refresh_token') || getItem('refresh_token');

    if (!accessToken || !refreshToken) return null;
    return {
        accessToken,
        refreshToken,
    };
}

class AuthStore {
    commonStore: CommonStore;
    token?: { accessToken: string; refreshToken: string } | null = getSavedToken();
    inProgress = false;
    baseLoad = false;
    signingIn = false;
    currentUser: User | null = null;
    subscriptions?: any | null = null;
    app: any = null;
    loginError?: string | null = null;
    emailLoginError?: string | null = null;
    signupError?: string | null = null;
    profileError?: string | null = null;
    verificationError?: string | null = null;
    verificationCheckError?: string | null = null;
    identityError?: string | null = null;
    identityCheckError?: string | null = null;
    updatingUser = false;
    gettingAuthUrl = false;
    eligibilityLoading: boolean | null = null;
    identityLoading: boolean | null = null;
    gettingVerifyStatus: boolean | null = null;
    resendingSMS = false;
    finalizing: boolean | null = null;
    pollVerificationResult: boolean | null = null;
    pollCounter = 0;
    verificationsAttempts = 0;
    resendSMSResult: any | null = null;
    authResult: IProveAuthResponse | null = null;
    verifyResult: IVerificationResultResponse | null = null;
    finalizeResult: IProveFinalizeResponse | null = null;
    eligibilityResult: IEligibilityResultResponse | null = null;
    identityResult: IIdentityResultResponse | null = null;

    constructor(commonStore: CommonStore) {
        makeAutoObservable(this, { commonStore: false });
        this.commonStore = commonStore;
        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    if (!this.currentUser) return this.pullUser();
                } else {
                    removeItem('access_token');
                }
            }
        );

        reaction(
            () => this.currentUser,
            (user) => {
                if (user) {
                    // do something on login
                } else {
                    // do something on logout
                }
            }
        );
    }

    setError(error: any, type = 'login') {
        if (error instanceof Error) {
            error = extractErrorMessage(error);
        }
        this.loginError = type === 'login' ? error : null;
        this.emailLoginError = type === 'emailLogin' ? error : null;
        this.signupError = type === 'signup' ? error : null;
        this.profileError = type === 'profile' ? error : null;
        this.verificationError = type === 'getAuthUrl' ? error : null;
        this.verificationCheckError = type === 'getVerifyStatus' ? error : null;
        this.identityError = type === 'identity' ? error : null;
        this.identityCheckError = type === 'finalize' ? error : null;
    }

    async logout(callback?: Function) {
        this.inProgress = false;
        this.forgetUser();
        this.setToken(null);
        if (callback) callback();
    }

    setToken(token: { accessToken: string; refreshToken: string } | null) {
        this.token = token;
    }

    get displayName() {
        if (!this.currentUser) return '';
        return this.currentUser.name || this.currentUser.email;
    }

    get isAuthenticated() {
        return this.currentUser != null;
    }

    forgetUser() {
        this.currentUser = null;
    }

    async refreshUser() {
        try {
            let user = {};
            // @ts-ignore
            Object.assign(this.currentUser, user);
        } catch (e) {
            console.error(e);
        }
    }

    async pullUser(counter = 0) {
        this.inProgress = true;
        this.baseLoad = true;
        try {
            if (this.token) {
                await services.Auth.verify(this.token.accessToken, this.token.refreshToken);

                const user = await services.UserProfile.fetch();

                runInAction(() => {
                    this.currentUser = user.userProfile;
                });
            } else {
                this.logout();
            }
        } catch (e: any) {
            runInAction(() => {
                this.inProgress = false;
                this.baseLoad = false;
            });
            if (counter < 10 && e && (!e.response || e.response.status !== 403 || e.response.status !== 401)) {
                setTimeout(async () => {
                    await this.pullUser(counter + 1);
                }, backOffDelay(500, counter));
            } else if (e?.response?.status === 401) {
                if (!!this.token) {
                    try {
                        const refreshResult = await services.Auth.refreshAccessToken(this.token.accessToken, this.token.refreshToken);
                        const accessToken = refreshResult.access_token;
                        const refreshToken = refreshResult.refresh_token;
                        this.setToken({ accessToken, refreshToken });
                        setItem('access_token', accessToken, 30);
                        setItem('refresh_token', refreshToken, 30);
                    } catch (e) {
                        this.setError(extractErrorMessage(e));
                    }
                } else {
                    this.setError(extractErrorMessage(e));
                }
            } else {
                return this.logout();
            }
        } finally {
            // console.log("done")
            runInAction(() => {
                this.inProgress = false;
                this.baseLoad = false;
                this.signingIn = false;
            });
        }
    }

    async signIn() {
        if (this.inProgress) return;
        this.inProgress = true;
        this.signingIn = true;
        try {
            // Initialize Firebase
            this.app = initializeApp(firebaseConfig);
            if (!this.token) {
                const username = process.env.REACT_APP_DEV_USER || '';
                const pwd = process.env.REACT_APP_DEV_PWD || '';

                const auth = getAuth();

                const userCredential = await signInWithEmailAndPassword(auth, username, pwd);

                const user = userCredential.user;
                const idToken = await user.getIdToken();
                const signInResult = await services.Auth.signIn(idToken);

                const accessToken = signInResult.access_token;
                const refreshToken = signInResult.refresh_token;
                this.setToken({ accessToken, refreshToken });
                setItem('access_token', accessToken, 30);
                setItem('refresh_token', refreshToken, 30);

                return true;
            }

            runInAction(() => {
                this.baseLoad = true; //set it here not not blink  spinner on button
            });
        } catch (e) {
            if (!!this.token) {
                try {
                    const refreshResult = await services.Auth.refreshAccessToken(this.token.accessToken, this.token.refreshToken);
                    const accessToken = refreshResult.access_token;
                    const refreshToken = refreshResult.refresh_token;
                    this.setToken({ accessToken, refreshToken });
                    setItem('access_token', accessToken, 30);
                    setItem('refresh_token', refreshToken, 30);
                } catch (e) {
                    this.setError(extractErrorMessage(e));
                }
            } else {
                this.setError(extractErrorMessage(e));
            }
        } finally {
            runInAction(() => {
                this.inProgress = false;
            });
        }
    }

    startPollVerificationResult() {
        this.pollVerificationResult = true;
        this.pollCounter = 0;
        setTimeout(() => {
            this.getVerifyStatus();
        }, 5000);
    }

    startOneTimeVerificationResult() {
        this.pollVerificationResult = true;
        this.pollCounter = 12;
        this.getVerifyStatus();
    }

    getAuthUrl = generateCreateEntity(
        'getAuthUrl',
        this,
        'gettingAuthUrl',
        async (phone: string) => {
            const result = await services.Auth.getAuthUrl(phone);
            if (!result || !result.verified)
                throw new Error(result.error_message || 'Phone number not valid. Please contact Customer Service');
            return result;
        },
        'authResult'
    );

    getVerifyStatus = generateCreateEntity(
        'getVerifyStatus',
        this,
        'gettingVerifyStatus',
        async (oneTime: boolean = false) => {
            const result = await services.Auth.getVerifyStatus();
            if (!oneTime && !result.phoneVerified?.possessionCheck)
                runInAction(() => {
                    this.pollCounter += 1;
                    if (this.pollCounter > 12) {
                        this.pollVerificationResult = false;
                    } else {
                        console.log('rerun check in 5 sec....');
                        setTimeout(() => {
                            this.getVerifyStatus();
                        }, 5000);
                    }
                });

            return result;
        },
        'verifyResult'
    );

    resendSMS = generateCreateEntity(
        'resendSMS',
        this,
        'resendingSMS',
        async () => {
            const result = await services.Auth.resendSMS();
            return result;
        },
        'resendSMSResult'
    );

    checkTrust = generateCreateEntity(
        'finalize',
        this,
        'finalizing',
        (vfp: string, userAuthGuid: string) => {
            return services.Auth.checkTrust(vfp, userAuthGuid);
        },
        'finalizeResult'
    );

    finalize = generateCreateEntity(
        'finalize',
        this,
        'finalizing',
        (vfp: string, userAuthGuid: string) => {
            return services.Auth.checkTrust(vfp, userAuthGuid);
        },
        'finalizeResult'
    );

    checkTrustRegistration = generateCreateEntity(
        'finalize',
        this,
        'finalizing',
        (vfp: string, userAuthGuid: string) => {
            return services.Auth.checkTrustRegistration(vfp, userAuthGuid);
        },
        'finalizeResult'
    );

    verifyIdentity = generateCreateEntity(
        'finalize',
        this,
        'finalizing',
        async (
            firstName: string,
            lastName: string,
            dob: string,
            last4: string,
            city: string,
            address: string,
            region: string,
            postalCode: string
        ) => {
            const result = await services.Auth.verifyIdentity(firstName, lastName, dob, last4, city, address, region, postalCode);
            if (!!result?.error_message) this.verificationsAttempts = 3;
            if (!result.verified) throw new Error('Verification failed, please contact Customer Service.');
            return result;
        },
        'finalizeResult'
    );

    resetIdentityError() {
        this.identityError = null;
        this.identityCheckError = null;
    }

    eligibility = generateCreateEntity(
        'eligibility',
        this,
        'eligibilityLoading',
        () => {
            return services.Auth.eligibility();
        },
        'eligibilityResult'
    );

    identity = generateCreateEntity(
        'identity',
        this,
        'identityLoading',
        async (dob: Date | null, last4: string | null) => {
            if (dob) {
                const result = await services.Auth.identity(dob, last4);
                if (result?.verified === null) throw new Error('Verification failed, please contact Customer Service.');
                return result;
            }
        },
        'identityResult'
    );

    get loginUrl() {
        return `/signin?redirect=${window.location.pathname}${window.location.search}`;
    }

    get loginGuid() {
        if (!this.token) return null;
        let access_token = parseJwt(this.token);
        return access_token.loginGuid;
    }

    get waitingForVerification() {
        if (!this.authResult) return false;
        return this.authResult.message === 'success' && this.authResult.verified;
    }

    get possessionCheckConfirmed() {
        if (!this.verifyResult) return false;
        //return false;
        return this.verifyResult.phoneVerified?.possessionCheckConfirmed;
    }

    get possessionChecked() {
        if (!this.verifyResult) return false;
        //return false;
        return this.verifyResult.phoneVerified?.possessionCheck;
    }

    get eligibilityForPrefill() {
        if (!this.eligibilityResult) return false;
        //return false;
        return this.eligibilityResult.eligibility;
    }
}

export default AuthStore;
