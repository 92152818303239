import React from 'react';
import { Badge, Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import SparkButton from '../../../components/SparkButton';
import GenericButton from '../../../components/GenericButton';

const MyImage = styled('img')(({ theme }) => ({
    backgroundSize: 'contain',
    margin: '14px 0',
    width: '100%',
    height: 'auto',
    maxWidth: '250px',
}));

const CustomBadge = styled('span')(({ theme }) => ({
    width: 'max-content',
    padding: '0px 12px',
    backgroundColor: '#e0a54a',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '4px'
}));

const Complete = () => {
    const onViewGiftCard = () => {
        // TODO
    }

    const onClose = () => {
        // TODO
    }

    return (
        <Container>
            <Box display="flex" flexDirection="column" alignItems={'center'}>
                <Typography variant="body1" fontWeight={'bold'}>Order Completed</Typography>
                <MyImage src={'https://via.placeholder.com/250'} />
                <CustomBadge>5% Back</CustomBadge>
                <Typography fontWeight={'bold'} variant="h3">Success!</Typography>
                <Typography variant="body1">You've successfully purchased <b>$100 Whole Foods</b> gift card.</Typography>
                <Box width={'100%'} maxWidth="600px" sx={{ position: 'absolute', bottom: 20, padding: '0 20px' }}>
                    <GenericButton onClick={onViewGiftCard}>View Gift Card</GenericButton>
                    <div style={{ marginBottom: '10px' }}></div>
                    <SparkButton onClick={onClose}>Close</SparkButton>
                </Box>
            </Box>
        </Container>
    )
};

export default Complete;