import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const GradientButton = styled(Button)`
    background: linear-gradient(
        91.26deg,
        #8ad4ec 0%,
        #ef96ff 21.74%,
        #ff56a9 54.03%,
        #ffaa6c 85.28%
    );
    color: white;
    border-radius: 80px;
`;

export default GradientButton;
