import Auth from './AuthService';
import MerchantService from './MerchantService';
import UserProfile from './UserProfileService';
import Web from './WebService';
import UtilityService from './UtilityService';

export default {
    Auth: new Auth(),
    UserProfile: new UserProfile(),
    Web: new Web(),
    Merchants: new MerchantService(),
    Utility: new UtilityService(),
};
