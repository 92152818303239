import RestService from './RestService';

export default class MerchantService extends RestService {
    constructor() {
        super('');
    }

    async getMerchants() {
        return this.get('https://run.mocky.io/v3/1ccc5197-1042-4096-977a-2bd6dcb1ec94');
    }
}
