import React, { ChangeEvent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DialogContent, Paper, Box, Typography, DialogActions, Alert, CircularProgress, Button } from '@mui/material';
import { useStores } from '../hooks/use-stores';
import DialogTitle from '../components/DialogTitle';
import TextField from '../components/MaskedMaterialTextField';
import { useNavigate } from 'react-router-dom';

interface Props {
    vfp?: string | null;
    finalize?: boolean;
}

export default observer(function PhoneVerification(props: Props) {
    const { authStore } = useStores();
    const navigate = useNavigate();
    const {
        verificationError,
        verificationCheckError,
        waitingForVerification,
        gettingAuthUrl,
        pollVerificationResult,
        gettingVerifyStatus,
        possessionChecked,
        possessionCheckConfirmed,
    } = authStore;
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const handleVerifyIdentity = () => {
        if (waitingForVerification) {
            authStore.startOneTimeVerificationResult();
        } else {
            authStore.getAuthUrl(phoneNumber);
        }
    };

    const resendSMS = () => {
        authStore.resendSMS();
    };
    const handleChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    };

    const isPhoneValid = useMemo(() => {
        return phoneNumber.length === 10;
    }, [phoneNumber]);

    useEffect(() => {
        void authStore.getVerifyStatus(true);
    }, []);

    useEffect(() => {
        if (waitingForVerification) {
            authStore.startPollVerificationResult();
        }
    }, [waitingForVerification]);

    useEffect(() => {
        if ((waitingForVerification && possessionChecked) || !!possessionCheckConfirmed) {
            navigate('/verify_identity');
        }
    }, [waitingForVerification, possessionChecked, possessionCheckConfirmed]);

    if (gettingVerifyStatus === true || gettingVerifyStatus === null || !!possessionCheckConfirmed)
        return (
            <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Box>
        );

    return (
        <Box
            sx={{
                color: '#575962',
                height: '100%',
                width: '100%',
                outline: 0,
                display: 'flex',
                alignItems: 'flexStart',
                justifyContent: 'center',
                position: 'fixed',
            }}
        >
            {pollVerificationResult}
            <Paper
                sx={{
                    margin: 0,
                    width: 450,
                    maxWidth: '98%',
                    backgroundColor: 'black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {waitingForVerification && <DialogTitle>Waiting for verification</DialogTitle>}
                <DialogContent
                    sx={(theme) => ({
                        height: '100%',
                        padding: theme.spacing(4),
                        paddingTop: 0,
                        textAlign: 'center',
                    })}
                >
                    {waitingForVerification ? (
                        <>
                            <Typography>Please click the link sent to your mobile number.</Typography>
                            {verificationCheckError && <Alert severity="error">{verificationCheckError}</Alert>}
                            {pollVerificationResult === false && (
                                <>
                                    <Typography>Didn't receive anything?</Typography>
                                    <Button color="primary" variant={'text'} onClick={resendSMS}>
                                        Resend Link
                                    </Button>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Typography>Before you can add money to your account, we first need to verify your identity. To start, please enter your Mobile Phone Number.</Typography>
                            <TextField
                                format="+1 (###) ###-####"
                                mask="_"
                                allowEmptyFormatting={true}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label={'Phone number (US only)'}
                                margin={'normal'}
                                value={phoneNumber}
                                onChange={handleChangePhoneNumber}
                            />
                            {verificationError && <Alert severity="error">{verificationError}</Alert>}
                        </>
                    )}
                    <Button
                        color="primary"
                        size="large"
                        fullWidth
                        variant="contained"
                        disabled={!isPhoneValid || gettingAuthUrl || pollVerificationResult === true}
                        onClick={handleVerifyIdentity}
                    >
                        {gettingAuthUrl || pollVerificationResult === true ? <CircularProgress size={26} /> : 'Next'}
                    </Button>
                </DialogContent>
            </Paper>
        </Box>
    );
});
