import { Box, styled, Typography, } from '@mui/material';

const NotchImage = styled('img')(({ theme }) => ({
    position: 'absolute',
    top: '10px',
    width: '50px',
    height: '25px'
}));

const Image = styled('img')(({ theme }) => ({
    maxWidth: '200px',
    height: 'auto'
}));

interface Props {
    backgroundColor: string
    logo: string
    amount?: string
    currency?: string
    fullWidth?: boolean
}

const GiftCardImage = (props: Props) => {
    return (
            <Box display="flex" alignItems={'center'} justifyContent='center' sx={{ position: 'relative', backgroundColor: props.backgroundColor, padding: '30px', borderRadius: 2}}>
                <Image src={props.logo} />
                <NotchImage src={`${process.env.PUBLIC_URL}/img/notch.svg`} />
                {props.amount && props.currency && 
                <Box sx={{color: 'black', position: 'absolute', top: 10, right: 10}}>
                    <Typography sx={{fontSize: 24, fontWeight: 900, marginBottom: -1}}>{props.amount}</Typography>
                    <Typography sx={{fontSize: 14, fontWeight: 'bold', textAlign: 'right'}}>{props.currency}</Typography>
                </Box>}
            </Box>
    )
}

export default GiftCardImage;