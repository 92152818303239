
import Merchant from './models/Merchant';
import {
    action,
    computed,
    makeObservable,
    observable,
} from 'mobx';
import { flatten, intersection, uniq } from 'lodash';
import countries from '../utils/countries';
import services from '../services';

const DEFAULT_COUNTRY = 'US';

interface AllMechants {
    featured: Merchant[];
    products: Merchant[];
}

class GiftcardStore {
    public merchants: AllMechants = { featured: [], products: [] };
    public selectedMerchant: Merchant | undefined = undefined;
    public countryCode: string = DEFAULT_COUNTRY;
    public searchQuery: string = '';
    public category: string = '';
    public orderTotal: number = 100.00; // default price to 100
    public error: Error | null = null;

    constructor() {
        makeObservable(this, {
            merchants: observable,
            selectedMerchant: observable,
            countryCode: observable,
            searchQuery: observable,
            category: observable,
            orderTotal: observable,
            error: observable,

            switchCountry: action,

            countryMerchants: computed,
            countryName: computed,
            merchantsOfSelectedCategory: computed
        });
    }

    async loadMerchants() {
        try {
            const stuff = await services.Merchants.getMerchants();
            this.merchants = stuff;
        } catch (e: any) {
            this.error = e;
        }
    }

    switchCountry(value: string) {
        this.clearFilters();
        this.countryCode = value;
    }

    clearFilters() {
        this.searchQuery = '';
    }

    merchantsByCategory = (category: string) => {
        if (category == 'featured') {
            return this.merchants.featured;
        }
        return this.countryMerchants.filter(m => m.type == category)
    }

    filterMerchantsByName = (name: string, merchants: Merchant[]) => {
        const regex = new RegExp(name, 'i');
        return merchants.filter(m => m.name.match(regex));
    }

    get countryMerchants() {
        return this.merchants.products.filter((x) => x.countryCode === this.countryCode);
    }

    get countryName() {
        let country = countries.find(
            (country) => country.code == this.countryCode
        );
        if (country) {
            return country.name;
        }
        return '';
    }

    get filters() {
        let filters: string[] = [];

        const filterableCategories = this.countryMerchants.map(
            (m) => m.type
        );

        filters = filters.concat(flatten(filterableCategories));

        return uniq(filters);
    }

    get filteredMerchants() {
        // Filter by Country
        let filteredMerchants = this.countryMerchants;

        // Filter by search query
        if (this.searchQuery) {
            filteredMerchants = this.filterMerchantsByName(this.searchQuery, filteredMerchants);
        }

        return filteredMerchants;
    }

    get merchantsOfSelectedCategory() {
        return this.merchantsByCategory(this.category)
    }
}

export default GiftcardStore;