import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Select } from '@mui/material';

const StyledSelect = styled(Select)(({ theme }) => ({
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 14,
    '& fieldset': {
        border: '1px solid #e2e2e1',
    },
    '&.Mui-focused': {
        '& fieldset': {
            borderWidth: '1px !important',
        },
    },
}));

export default StyledSelect;
