import stores from '../stores';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { v4 as uuid } from 'uuid';
const appVersion = require('../../package.json').version;

const API_ROOT = process.env.REACT_APP_API_URL;
const appId = uuid();
const SHOPPING_BOSS_VERSION = '5.4';

class BaseService {
    handleErrors(err: any) {
        if (err && err.response && err.response.status === 401) {
            stores.authStore.logout();
        }
        return err;
    }

    baseUrl: string;
    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    commonHeaders() {
        const headers = {
            'X-App-Request-Id': `${appId}`,
            'X-App-Version': `${appVersion}`,
            'X-Version': SHOPPING_BOSS_VERSION,
        } as AxiosRequestHeaders;

        if (stores.authStore.token && stores.authStore.token.accessToken)
            headers[
                'Authorization'
            ] = `Bearer ${stores.authStore.token.accessToken}`;
        return { headers };
    }

    async del(url: string, body?: any) {
        try {
            const response = await axios.delete(url, {
                baseURL: API_ROOT,
                ...this.commonHeaders(),
                data: body,
            });
            return response.data;
        } catch (e) {
            const notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }

    async get(url: string, params?: any) {
        try {
            const response = await axios.get(url, {
                params,
                baseURL: API_ROOT,
                ...this.commonHeaders(),
            });
            return response.data;
        } catch (e) {
            const notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }

    async put(url: string, body: any, options?: AxiosRequestConfig) {
        try {
            const config = { ...this.commonHeaders(), ...options };
            if (!url.startsWith('http://') && !url.startsWith('https://'))
                config.baseURL = API_ROOT;
            const response = await axios.put(url, body, config);
            return response.data;
        } catch (e) {
            const notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }
    async patch(url: string, body: any, options?: AxiosRequestConfig) {
        try {
            const config = { ...this.commonHeaders(), ...options };
            if (!url.startsWith('http://') && !url.startsWith('https://'))
                config.baseURL = API_ROOT;
            const response = await axios.patch(url, body, config);
            return response.data;
        } catch (e) {
            const notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }

    async post(url: string, body?: any, options?: AxiosRequestConfig) {
        try {
            const config = {
                baseURL: API_ROOT,
                ...this.commonHeaders(),
                ...options,
            };
            const response = await axios.post(url, body, config);
            return response.data;
        } catch (e) {
            const notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }
}

export default BaseService;
