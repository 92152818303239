import RestService from './RestService';

export default class UtilityService extends RestService {
    constructor() {
        super('');
    }

    async getRegionByCountry(country: string) {
        return this.get(`https://api.lifeinfoapp.com/public/util/regions/${country}`);
    }
}
