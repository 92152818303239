import React from 'react';
import { useLocation, Navigate } from 'react-router';
import { useStores } from '../hooks/use-stores';

function RequireAuth({
    children,
    notLoggedUrl,
}: {
    children: JSX.Element;
    notLoggedUrl?: string;
}) {
    const { authStore } = useStores();
    if (!notLoggedUrl) notLoggedUrl = authStore.loginUrl;
    let location = useLocation();

    if (!authStore.isAuthenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return (
            <Navigate to={notLoggedUrl} state={{ from: location }} replace />
        );
    }

    return children;
}

export default RequireAuth;
