import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';

const GenericButton = styled(Button)<ButtonProps>`
padding: 12px 30px 10px;
letter-spacing: -0.5px;
display: inline-block;
border-radius: 100px;
font-family: "colfax-medium", sans-serif;
-webkit-transition: all 0.4s ease;
transition: all 0.4s ease;
text-align: center;
cursor: pointer;
color: #fff;
width: 100%;
background-color: rgba(144, 202, 249, 0.08);
text-transform: none;
}
`;

export default GenericButton;
