import IdLessRestService from './IdLessRestService';
import BaseService from './BaseService';

export default class UsernameService extends IdLessRestService {
    constructor(parentService: BaseService) {
        if (parentService) {
            super(`${parentService.baseUrl}/username`);
        } else throw new Error('Can be only nested service');
    }

    async validateUserName(username: string) {
        return this.get(`${this.baseUrl}/validate`, { username });
    }

    async validateUserNameEnroll(username: string) {
        return this.post(`/public/enroll/customer/email/validate`, {
            email: username,
        });
    }
}
