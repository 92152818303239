const countries = [
    {
        name: 'United States',
        code: 'US',
        flag: `🇺🇸`,
    },
    {
        name: 'Canada',
        code: 'CA',
        flag: `🇨🇦`,
    },
];

export default countries;
