import { makeAutoObservable, runInAction } from 'mobx';
import keyBy from 'lodash/keyBy';
import i18n from '../tools/i18n';
import { setItem, getItem } from '../utils/storage';
import { extractErrorMessage } from '../utils/helpers';
import { generateLoadList } from '../utils/mobx';
import services from '../services';

enum MessageType {
    Success,
    Info,
    Warning = 2,
    Error = 3,
}

class CommonStore {
    onVerify: boolean | null = null;
    error: string | null = null;
    message: string | null = null;
    appLoaded = false;
    messageType: MessageType | null = null;
    messageShown = false;
    messageTimeout: NodeJS.Timeout | null = null;
    confirmationRequired = false;
    confirmationShowCancel = true;
    confirmationDescription: string | null = null;
    confirmationHtmlDescription = false;
    confirmationAction: string | null = null;
    confirmationOnConfirmAction: Function | null = null;
    confirmationTitle: string | null = null;
    confirmationCancelTitle: string | null = 'Cancel';
    languagesLoaded = false;
    statusLoading = false;
    notificationsLoading = false;
    language: string | null = getItem('language') || 'en';
    languages = ['en', 'fr', 'es'];
    i18n: any | null = null;
    languagesLoader: Promise<any>;
    languagesLoaderResolver: Function | null = null;
    regionsByCountry: any = {};
    regionsByCountryLoading = false;

    constructor() {
        makeAutoObservable(this);
        this.languagesLoader = new Promise((resolve) => {
            this.languagesLoaderResolver = resolve;
        });
    }

    setError(error: any) {
        error = extractErrorMessage(error);
        this.error = error;
    }

    hideMessage() {
        this.message = null;
        this.messageShown = false;
        this.messageShown = false;
        try {
            if (this.messageTimeout) {
                clearTimeout(this.messageTimeout);
            }
        } catch (e) { }
        this.messageTimeout = null;
    }

    setAppLoaded() {
        this.appLoaded = true;
    }

    success(message: string) {
        this.showMessage(message, MessageType.Success);
    }

    warn(message: string) {
        this.showMessage(message, MessageType.Warning);
    }

    showError(message: string) {
        this.showMessage(message, MessageType.Error);
    }

    showMessage(message: string, type: MessageType = MessageType.Info, delay = 10000) {
        this.hideMessage();
        if (message) {
            this.message = message;
            this.messageShown = true;
            this.messageType = type;
            this.messageTimeout = setTimeout(() => {
                this.hideMessage();
            }, delay);
        }
    }

    getEntityName(entityType: string, entity: any) {
        switch (entityType) {
            case 'Address':
                return `address: "${entity.addressName}"`;
            case 'User':
                return entity.name || entity.email;
            default:
                return entity;
        }
    }

    showDeleteConfirm(entityType: string, entity: any, callback: Function) {
        this.showConfirm(
            `Do you really want to delete ${this.getEntityName(entityType, entity)}`,
            'Delete',
            `Delete ${entityType}`,
            callback
        );
    }

    showConfirm(
        confirmationDescription: string,
        confirmationAction: string,
        confirmationTitle: string,
        confirmationOnConfirmAction: Function,
        confirmationCancelTitle = 'Cancel',
        { htmlDescription = false } = {}
    ) {
        this.confirmationDescription = confirmationDescription;
        this.confirmationAction = confirmationAction;
        this.confirmationTitle = confirmationTitle;
        this.confirmationCancelTitle = confirmationCancelTitle;
        this.confirmationOnConfirmAction = confirmationOnConfirmAction;
        this.confirmationRequired = true;
        this.confirmationHtmlDescription = htmlDescription;
    }

    cancelConfirm() {
        this.confirmationDescription = null;
        this.confirmationAction = null;
        this.confirmationTitle = null;
        this.confirmationOnConfirmAction = null;
        this.confirmationCancelTitle = null;
        this.confirmationRequired = false;
        this.confirmationHtmlDescription = false;
    }

    doConfirm(args: any) {
        if (this.confirmationOnConfirmAction) this.confirmationOnConfirmAction(args);

        this.cancelConfirm();
    }

    //potential ability to load available languages dynamically
    async loadLanguages() {
        this.languagesLoaded = false;

        try {
            const i18 = await i18n.init();
            if (this.languagesLoaderResolver) {
                this.languagesLoaderResolver();
            }
            runInAction(() => {
                this.i18n = i18;
                this.languagesLoaded = true;
            });
        } catch (e) {
            setTimeout(async () => {
                await this.loadLanguages();
            }, 5000);
        }
    }

    async setLanguage(language_code: string | null, skipSaving: boolean) {
        await this.languagesLoader;
        if (!skipSaving) {
            // TODO: save user language if need
            //await services.Users.switchLanguage(language_code);
            setItem('language', language_code);
        }
        this.language = language_code;

        this.i18n.changeLanguage(language_code);
        //this.loadServerData()
    }

    get languagesIndex() {
        return keyBy(this.languages, 'code');
    }

    loadRegionsByCountry = generateLoadList('regionsByCountry', this, 'regionsByCountryLoading', async (country: string) => {
        const [{ regions }] = await services.Utility.getRegionByCountry(country);
        runInAction(() => {
            this.regionsByCountry[country] = regions;
        });
    });
}

export default CommonStore;
