import { action, observable, runInAction } from 'mobx';
import { backOffDelay, extractErrorMessage, isArray } from './helpers';
import Promise from 'bluebird';
import getPath from 'lodash/get';

export function generateLoadList(
    name,
    store,
    loadFlag,
    loaderFn,
    targetProp,
    resultPath,
    loadOptions
) {
    if (store[loadFlag] === undefined) {
        store[loadFlag] = observable(observable.box(false));
        store[loadFlag] = false;
    }
    if (store[targetProp] === undefined) {
        store[targetProp] = observable([]);
    }

    return action(async function () {
        if (store[loadFlag] && !(loadOptions && loadOptions.isRetry)) return;
        store[loadFlag] = true;
        store.setError(null, name);

        try {
            let result = await loaderFn.apply(store, arguments);
            if (resultPath) {
                if (typeof resultPath === 'function') {
                    result = resultPath(result, ...arguments);
                } else result = getPath(result, resultPath);
            }
            if (targetProp) {
                runInAction(() => {
                    store[targetProp].replace(result || []);
                });
            }
            return true;
        } catch (e) {
            if (
                loadOptions &&
                loadOptions.retries &&
                loadOptions.counter < loadOptions.retries
            ) {
                await Promise.resolve().delay(
                    backOffDelay(1000, loadOptions.counter)
                );
                return generateLoadList(
                    name,
                    store,
                    loadFlag,
                    loaderFn,
                    targetProp,
                    resultPath,
                    {
                        ...loadOptions,
                        counter: loadOptions.counter + 1,
                        isRetry: true,
                    }
                ).apply(store, arguments);
            } else {
                runInAction(() => {
                    store.setError(extractErrorMessage(e), name);
                });
            }
        } finally {
            runInAction(() => {
                store[loadFlag] = false;
            });
        }
    });
}

export function generateLoadList2(
    name,
    store,
    loadFlag,
    loaderFn,
    targetProp,
    resultPath
) {
    if (store[loadFlag] === undefined) {
        store[loadFlag] = observable([]);
    }
    if (store[targetProp] === undefined) {
        store[targetProp] = observable([]);
    }

    return action(async (key, options) => {
        if (store[loadFlag].includes(key)) return;
        store[loadFlag].push(key);
        store.setError(null, name);

        try {
            let result = await loaderFn(options);
            if (resultPath) {
                result = getPath(result, resultPath);
            }
            if (targetProp) {
                runInAction(() => {
                    store[targetProp].replace(result);
                });
            }
            return true;
        } catch (e) {
            runInAction(() => {
                store.setError(extractErrorMessage(e), name);
            });
        } finally {
            runInAction(() => {
                store[loadFlag].remove(key);
            });
        }
    });
}

export function generateLoadEntity(
    name,
    store,
    loadFlag,
    loaderFn,
    targetProp,
    resultPath,
    adapter,
    loadOptions = {}
) {
    return action(async function () {
        if (store[loadFlag]) return;
        store[loadFlag] = true;
        store.setError(null, name);
        if (loadOptions.clearResultBeforeLoad) {
            if (typeof targetProp === 'function') {
                targetProp(null);
            } else store[targetProp] = null;
        }
        try {
            let result = await loaderFn.apply(store, arguments);
            if (adapter && typeof adapter === 'function')
                result = adapter(result, ...arguments);
            if (resultPath) result = getPath(result, resultPath);
            runInAction(() => {
                if (typeof targetProp === 'function') {
                    targetProp(result);
                } else store[targetProp] = result;
            });
            return true;
        } catch (e) {
            if (loadOptions && loadOptions.throwError) throw e;
            if (
                loadOptions &&
                loadOptions.retries &&
                loadOptions.counter < loadOptions.retries
            ) {
                await Promise.resolve().delay(
                    backOffDelay(1000, loadOptions.counter)
                );

                return generateLoadEntity(
                    name,
                    store,
                    loadFlag,
                    loaderFn,
                    targetProp,
                    resultPath,
                    adapter,
                    { ...loadOptions, counter: loadOptions.counter + 1 }
                ).apply(arguments);
            } else
                runInAction(() => {
                    store.setError(extractErrorMessage(e), name);
                });
        } finally {
            runInAction(() => {
                store[loadFlag] = false;
            });
        }
    });
}

export function generateCreateEntity(
    name,
    store,
    creatingFlag,
    createFn,
    targetProp,
    options = {}
) {
    return action(async function () {
        if (store[creatingFlag]) return;
        store[creatingFlag] = true;
        store.setError(null, name);
        try {
            const result = await createFn.apply(store, arguments);
            if (targetProp) {
                runInAction(() => {
                    store[targetProp] = result;
                });
            }
            if (options.onCreated) options.onCreated(result, ...arguments);
            return result;
        } catch (e) {
            if (options && options.throwError) throw e;
            runInAction(() => {
                store.setError(extractErrorMessage(e), name);
            });
        } finally {
            runInAction(() => {
                store[creatingFlag] = false;
            });
        }
    });
}

export function generateUpdateEntity(
    name,
    store,
    updatingArray,
    updateFn,
    onUpdated,
    options
) {
    if (store[updatingArray] === undefined)
        store[updatingArray] = observable([]);
    return action(async function (key) {
        store.setError(null, name);
        if (Array.isArray(store[updatingArray])) {
            if (store[updatingArray].includes(key)) return;
            store[updatingArray].push(key);
        } else {
            if (store[updatingArray]) return;
            store[updatingArray] = true;
        }
        try {
            const result = await updateFn.apply(store, arguments);
            if (onUpdated) onUpdated(result);
            return result;
        } catch (e) {
            if (options && options.throwError) throw e;
            runInAction(() => {
                store.setError(extractErrorMessage(e), name);
            });
        } finally {
            runInAction(() => {
                if (Array.isArray(store[updatingArray])) {
                    store[updatingArray].remove(key);
                } else store[updatingArray] = false;
            });
        }
    });
}

export function generateDeleteEntity(
    name,
    store,
    removingArray,
    deleteFn,
    options
) {
    if (store[removingArray] === undefined)
        store[removingArray] = observable([]);
    store.setError(null, name);

    return action(async function (key, onDeleted) {
        if (isArray(store[removingArray])) {
            if (store[removingArray].includes(key)) return;
            store[removingArray].push(key);
        } else {
            if (store[removingArray]) return;
            store[removingArray] = true;
        }
        try {
            const result = await deleteFn.apply(store, arguments);
            if (onDeleted) onDeleted(result);
            return result;
        } catch (e) {
            if (options && options.throwError) throw e;
            if (options && options.snackbarError)
                return this.commonStore.showError(
                    extractErrorMessage(e) || options.defaultErrorMessage
                );

            runInAction(() => {
                store.setError(extractErrorMessage(e), name);
            });
        } finally {
            runInAction(() => {
                if (isArray(store[removingArray])) {
                    store[removingArray].remove(key);
                } else store[removingArray] = false;
            });
        }
    });
}
