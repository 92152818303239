import React, { ChangeEvent } from 'react';
import { observer } from "mobx-react-lite";
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
    searchQuery: string;
    onSearchQueryChanged: (event: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    isSmall?: boolean
}

const Search = observer((props: Props) => {
    return (
        <FormControl fullWidth variant="outlined">
            <OutlinedInput
                size={props.isSmall ? 'small' : 'medium'}
                placeholder={props.placeholder || 'Search for products'}
                value={props.searchQuery}
                autoComplete={'off'}
                onChange={props.onSearchQueryChanged}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
                // sx={{borderRadius: props.isSmall ? '10px' : '0px'}}
            />
        </FormControl>
    )
})

export default Search